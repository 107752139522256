import { last } from 'lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';
import afterQuery from './after.gql';
import assignNotificationMutation from './assign-notification.gql';
import markNotificationsAsReadMutation from './mark-notifications-as-read.gql';
import query from './notifications.gql';
import resolveNotificationMutation from './resolve-notification.gql';
import {
  CoreNotificationsMixinAfterQuery,
  CoreNotificationsMixinAfterQueryVariables,
} from './__generated__/CoreNotificationsMixinAfterQuery';
import {
  CoreNotificationsMixinAssignNotificationMutation,
  CoreNotificationsMixinAssignNotificationMutationVariables,
} from './__generated__/CoreNotificationsMixinAssignNotificationMutation';
import {
  CoreNotificationsMixinMarkNotificationsAsReadMutation,
  CoreNotificationsMixinMarkNotificationsAsReadMutationVariables,
} from './__generated__/CoreNotificationsMixinMarkNotificationsAsReadMutation';
import {
  CoreNotificationsMixinQuery,
  CoreNotificationsMixinQueryVariables,
} from './__generated__/CoreNotificationsMixinQuery';
import {
  CoreNotificationsMixinResolveNotificationMutation,
  CoreNotificationsMixinResolveNotificationMutationVariables,
} from './__generated__/CoreNotificationsMixinResolveNotificationMutation';

type Notification = CoreNotificationsMixinQuery['notifications']['items'][number];

@Component({
  apollo: {
    after: {
      query: afterQuery,
      pollInterval: 15000,
      variables(this: NotificationsMixin): CoreNotificationsMixinAfterQueryVariables {
        return {
          after: this.latestSeen,
          read: this.read,
          contextIds: this.contextIds,
          excludeIds: this.notifications?.items
            .filter(({ creationDate }) => creationDate >= this.latestSeen)
            .map(({ id }) => id),
        };
      },
    },
    notifications: {
      query,
      variables(this: NotificationsMixin): CoreNotificationsMixinQueryVariables {
        return { take: this.take, skip: this.skip, read: this.read, contextIds: this.contextIds, types: this.types };
      },
    },
  },
  data() {
    return { notifications: undefined, after: undefined, latestSeen: new Date() };
  },
})
export class NotificationsMixin extends Vue {
  protected readonly notifications?: CoreNotificationsMixinQuery['notifications'];
  private readonly after?: CoreNotificationsMixinAfterQuery['after'];

  private latestSeen!: Date;

  protected get skip(): CoreNotificationsMixinQueryVariables['skip'] {
    return undefined;
  }

  protected get take(): CoreNotificationsMixinQueryVariables['take'] {
    return undefined;
  }

  protected get read(): CoreNotificationsMixinQueryVariables['read'] {
    return undefined;
  }

  protected get contextIds(): CoreNotificationsMixinQueryVariables['contextIds'] {
    return undefined;
  }

  protected get types(): CoreNotificationsMixinQueryVariables['types'] {
    return undefined;
  }

  protected get empty(): boolean {
    return !this.$apollo.queries.notifications.loading && this.notifications?.items.length === 0;
  }

  protected get reloadCount(): number {
    return this.after?.count ?? 0;
  }

  @Watch('notifications')
  private updateLatestSeen(): void {
    this.latestSeen =
      this.notifications?.items.reduce((latestSeen, { creationDate }) => {
        return creationDate > latestSeen ? creationDate : latestSeen;
      }, this.latestSeen) ?? this.latestSeen;
  }

  protected async refetchNotifications(): Promise<void> {
    await this.$apollo.queries.notifications.refetch();
  }

  protected async changeReadAction(notification: Notification, read: boolean): Promise<void> {
    await this.$apollo.mutate<
      CoreNotificationsMixinMarkNotificationsAsReadMutation,
      CoreNotificationsMixinMarkNotificationsAsReadMutationVariables
    >({
      mutation: markNotificationsAsReadMutation,
      variables: { input: { notificationIds: [notification.id], types: this.types, unread: !read } },
    });
  }

  protected async changeAssigneeAction(
    notification: Notification,
    newAssignee: Notification['assignee'],
  ): Promise<void> {
    await this.$apollo.mutate<
      CoreNotificationsMixinAssignNotificationMutation,
      CoreNotificationsMixinAssignNotificationMutationVariables
    >({
      mutation: assignNotificationMutation,
      variables: { input: { notificationId: notification.id, assigneeId: newAssignee?.id } },
    });
  }

  protected async editResolutionAction(
    notification: Notification,
    resolved: Notification['resolved'],
    notes: Notification['notes'],
  ): Promise<void> {
    await this.$apollo.mutate<
      CoreNotificationsMixinResolveNotificationMutation,
      CoreNotificationsMixinResolveNotificationMutationVariables
    >({
      mutation: resolveNotificationMutation,
      variables: { input: { notificationId: notification.id, reset: !resolved, notes } },
    });
  }

  protected async markAllAsRead(): Promise<void> {
    await this.$apollo.mutate<
      CoreNotificationsMixinMarkNotificationsAsReadMutation,
      CoreNotificationsMixinMarkNotificationsAsReadMutationVariables
    >({
      mutation: markNotificationsAsReadMutation,
      variables: {
        input: {
          contextIds: this.contextIds,
          notificationIds: this.notifications?.items.map(({ id }) => id),
          types: this.types,
          until: last(this.notifications?.items)?.creationDate,
        },
      },
    });
  }
}
